import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

//首页banner轮播图
var swiper1 = new Swiper('.index-banner .swiper-container', {
  loop: true,
  autoplay: {
    delay: 6000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});


//首页学术课堂 swiper
var swiper2 = new Swiper('.index-collection .swiper1 .swiper-container', {
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 10,
  grabCursor: true,
  grid: {
    fill: 'column',
    rows: 2,
  },
  observer: true,
  observeParents: true,
  pagination: {
    el: '.index-collection .swiper1 .swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.index-collection .swiper1 .swiper-button-next',
    prevEl: '.index-collection .swiper1 .swiper-button-prev',
  },
  breakpoints: {
    425: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      grabCursor: true,
      grid: {
        fill: 'column',
        rows: 2,
      },
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 20,
      grid: {
        fill: 'column',
        rows: 1,
      },
    },
    1024: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 30,
      grid: {
        fill: 'column',
        rows: 1,
      },
    }
  }
});
var swiper3 = new Swiper('.index-collection .swiper2 .swiper-container', {
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 10,
  grabCursor: true,
  grid: {
    fill: 'column',
    rows: 2,
  },
  observer: true,
  observeParents: true,
  pagination: {
    el: '.index-collection .swiper2 .swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.index-collection .swiper2 .swiper-button-next',
    prevEl: '.index-collection .swiper2 .swiper-button-prev',
  },
  breakpoints: {
    425: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      grabCursor: true,
      grid: {
        fill: 'column',
        rows: 2,
      },
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 20,
      grid: {
        fill: 'column',
        rows: 1,
      },
    },
    1024: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 30,
      grid: {
        fill: 'column',
        rows: 1,
      },
    }
  }
});

//首页学术课堂移动端 tab切换
!(function(){
  $('.index-collection .mobtabs a').eq(0).addClass('active')
})()
$('.index-collection .mobtabs a').on('click',function(){
  // if(window.screen.width <= 992){
    console.log($(this).index())
    $('.index-collection .mobtabs a').eq($(this).index()).addClass('active').siblings().removeClass('active')
    $('.index-collection .index-collection-cont .index-collection-item').eq($(this).index()).show().siblings().hide()
  // }
})
// 监听浏览器大小改变
$(window).resize(function () {
  if (window.screen.width > 992) {
    $('.index-collection .index-collection-cont .index-collection-item').show()
    $('.index-collection .mobtabs a').eq(0).addClass('active').siblings().removeClass('active')
  }
});

//首页电脑端专家专栏效果
// $('.index-author-list-pc .author-list .item').on('click',function () {
//   if ($(this).hasClass('active')){
//     $(this).removeClass('active')
//     $('.author-detail').slideUp(200).removeClass('active')
//   }else{
//     $('.index-author-list-pc .author-list .item').removeClass('active')
//     $(this).addClass('active')
//     let detailEl = $(this).parents('.index-author-list-item').find('.author-detail')
//     let hasClassActive = $(detailEl).hasClass('active')
//     if (!hasClassActive) {
//       $('.author-detail').slideUp(200).removeClass('active')
//       $(detailEl).slideDown(200).addClass('active')
//     }
//   } 
// })

//首页手机端专家专栏效果
var swiper2 = new Swiper('.index-author-list-mob .swiper-container', {
  slidesPerView: 1,
  autoHeight: true,
  spaceBetween: 20,
  pagination: {
    el: '.index-author-list-mob .swiper-pagination',
    clickable: true,
  },
});


//页面滚动监听
// var isScroll = false;
// var beforeScroH = $(document).scrollTop();
// $(document).scroll(function () {
//   var scroH = $(document).scrollTop(); //滚动高度
//   var viewH = $(window).height(); //可见高度
//   var contentH = $(document).height(); //内容高度
//   var bannerTop = $('.index-news').offset().top;

//   //判断页面是上滚还是下滚
//   var delta = scroH - beforeScroH;
//   if (window.screen.width > 1200){
//     if (scroH == 0) {
//       isScroll = false;
//     }
//     if (delta > 0) {
//       if (scroH > 2 && scroH < bannerTop / 2 && isScroll == false) {
//         isScroll = true;
//         $('html ,body').animate({
//           scrollTop: $('.index-news').offset().top - 100
//         }, 300);
//         return false;
//       }
//     }
//     if (delta < 0) {
//       if (scroH < bannerTop && scroH > bannerTop / 2 && isScroll == true) {
//         isScroll = false;
//         $('html ,body').animate({
//           scrollTop: 0
//         }, 300);
//         return false;
//       }
//     }
//   }
//   beforeScroH = scroH;
// });

var num = 0;
function goLeft() {
    //750是根据你给的尺寸，可变的
    if (num == -750) {
        num = 0;
    }
    num -= 1;
    $(".index-notice .text").css({
        left: num
    })
}
//设置滚动速度
var timer = setInterval(goLeft, 20);
//设置鼠标经过时滚动停止
// $(".box").hover(function() {
//     clearInterval(timer);
// },
// function() {
//     timer = setInterval(goLeft, 20);
// })